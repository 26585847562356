<template>
  <div>
    <div class="about" style="background: #4451b4">
      <div class="w-100 d-flex align-items-center">
        <Nav msg="Historias Clinicas RMV" />
        <div class="dropdown">
          <fa
            @click="Mostrar = true"
            id="ellipsis-v"
            style="
              width: 30px;
              cursor: pointer;
              position: absolute;
              right: 0%;
              color: white;
              font-size: 1.1rem;
            "
            icon="ellipsis-v"
          />
          <div v-if="Mostrar" class="dropdown-content">
            <p style="cursor: pointer; color: black" @click="modalDescargar = true">
              Descargar HC
            </p>
            <p style="cursor: pointer; color: black" @click="salir()">Salir</p>
          </div>
        </div>
      </div>
      <!--
            Esta seccion aquí nos sirve para desplegar un mini menu que mostrara la opción para salir
        -->
      <div
        class="w-100 d-flex flex-column align-items-center"
        style="background: rgba(48, 62, 160, 255)"
      >
        <p style="color: white">
          {{ datos.nombre + " " + datos.apellido + " " }}<span>&#183; </span
          >{{ datos.documento }}
        </p>
        <p style="color: white">Móvil <span>&#183; </span>{{ movil }}</p>
      </div>
      <!--
            Esta seccion aquí nos sirve para mostrar algunos datos asociados al usuario que ingreso con el login
         -->
    </div>
    <!--
        Toda esta sección podríamos tomarlo como la cabecera que repetiremos en muchas vistas
    -->
    <div
      v-bind:style="{ background: colores2[coloresAplicar2[i - 1]] }"
      @click="
        redirigir(
          consecutivos[i - 1],
          colores[coloresAplicar[i - 1]],
          texto[i - 1],
          textoLe[i - 1]
        )
      "
      class="listas"
      v-for="i in consecutivos.length"
      :key="i"
    >
      <span>{{ textoLe[i - 1] }}</span> {{ consecutivos[i - 1] }}
      <button style="background: black; color: white; width: 20px; margin-left: 5px">
        {{ reqord[i - 1] }}
      </button>
      <i
        class="pi pi-envelope"
        style="font-size: 2rem; margin-left: 1rem"
        v-if="mail[i - 1]"
      ></i>
      <button
        v-bind:style="{ background: colores[coloresAplicar[i - 1]] }"
        style="margin-left: auto; color: white"
      >
        {{ texto[i - 1] }}
      </button>
    </div>
    <!--
        Toda esta sección será la visualización de los consecutivos asociados a la movil que no han sido terminados.
        Saldrá un div que funcionará en si mismo como un gran boton que nos permitira redirigir y enviar algunos datos tales como el consecutivo, el color y el texto,
        esto para usarlo en la siguiente vista. El color y el texto estan dados en funcion del reqcda que es el triaje
        Este tendrá unas funcionalidades tales como cambiar de color el fondo en función del reqreg, mostrar el contenido en función del color asignado al triaje
    -->
    <h1 v-if="consecutivosMostrar" class="text-center" style="color: black">
      No Tiene Consecutivos Asignados
    </h1>
    <!--
        Esto solo se mostrará en caso de que no existan consecutivos por mostrar
    -->
    <div class="d-flex justify-content-center mt-3">
      <Button
        style="color: white"
        class="p-button-raised mb-3"
        label="RECARGAR"
        @click="recargar()"
      />
    </div>
    <div v-if="modalDescargar">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header w-100">
                  <div class="d-flex w-100" style="justify-content: space-evenly">
                    <div style="padding-left: 5%">
                      <h1 style="color: black">Ingrese el consecutivo a descargar</h1>
                      <input
                        maxlength="5"
                        v-model="consecutivo"
                        class="w-100"
                        style="
                          border: 0;
                          padding: 1rem;
                          border-bottom: 1px solid #ced4da;
                          height: 42px;
                        "
                        type="number"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <button
                      @click="modalDescargar = false"
                      style="color: red; widht: 100px; position: relative; top: 5px"
                    >
                      CERRAR
                    </button>
                    <button
                      @click="descargar(),modalDescargar = false"
                      style="
                        left: 10px;
                        color: green;
                        widht: 100px;
                        position: relative;
                        top: 5px;
                      "
                    >
                      DESCARGAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div id="boxLoading"></div>
    <!--Este boton sirve para recargar los datos de visualización de los consecutivos-->
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import axios from "axios";
import $ from "jquery";
var url = "/php/crud.php";
export default {
  name: "Home",
  components: {
    Nav,
  },
  data() {
    return {
      datos: JSON.parse(localStorage.getItem("datos")), //Datos asignados previamente por localstorage
      movil: localStorage.getItem("movil"), //Movil asignada por el localstorage movil
      Mostrar: false, //Estado visibilidad menu
      consecutivos: [], //Listado de consecutivos asignados a la movil
      colores: ["red", "#c4c416", "#0073ff", "green", "grey"], //Colores que usaremos variando el triaje
      colores2: ["#8080808c", "white", "#dfe75e"], //Colores que usaremos de fondo dependiendo el reqreg
      reqord: [], //Dato que mostraremos después del consecutivo
      coloresAplicar: [], //Colores que aplicaremos a los botones
      coloresAplicar2: [], //Colores que aplicaremos al background
      texto: [], //Texto ue aplicaremos a los botones
      consecutivosMostrar: false, //Estado visibilidad texto no tiene consecutivos asignados
      textoLe: [],
      mail: [],
      modalDescargar: false,
      consecutivo: null
    };
  },
  mounted() {
    $("#boxLoading").addClass("loading");
    var prueba = localStorage.getItem("prueba");
    if (prueba === null) {
      this.$router.replace({
        name: "Home",
      });
    }
    //Si no existe prueba regresaremos al usuario al login

    axios
      .post(url, {
        movil: localStorage.getItem("movil"),
        opcion: 2,
      })
      .then((response) => {
        try {
          response.data.data.forEach((element) => {
            this.consecutivos.push(element.reqcsc);
            this.reqord.push(element.reqord);
            var colorImportante;

            console.log(element.bandera_estado_lectura_examenes);
            if (element.estado_lectura_examenes == 1) {
              colorImportante = true;
            }
            if (element.correo_enviado == 1) {
              this.mail.push(true);
            } else {
              this.mail.push(false);
            }
            switch (element.reqcda) {
              case "V":
                if (colorImportante == true) {
                  element.bandera_estado_lectura_examenes == "1"
                    ? this.coloresAplicar2.push(2)
                    : this.coloresAplicar2.push(0);
                  this.textoLe.push("LE");
                } else {
                  element.reqreg == "1"
                    ? this.coloresAplicar2.push(0)
                    : this.coloresAplicar2.push(1);
                  this.textoLe.push("");
                }

                this.coloresAplicar.push(3);
                this.texto.push("VERDE");
                break;
              case "A":
                if (colorImportante == true) {
                  element.bandera_estado_lectura_examenes == "1"
                    ? this.coloresAplicar2.push(2)
                    : this.coloresAplicar2.push(0);
                  this.textoLe.push("LE");
                } else {
                  element.reqreg == "1"
                    ? this.coloresAplicar2.push(0)
                    : this.coloresAplicar2.push(1);
                  this.textoLe.push("");
                }

                this.coloresAplicar.push(1);
                this.texto.push("AMARILLO");
                break;
              case "R":
                if (colorImportante == true) {
                  element.bandera_estado_lectura_examenes == "1"
                    ? this.coloresAplicar2.push(2)
                    : this.coloresAplicar2.push(0);
                  this.textoLe.push("LE");
                } else {
                  element.reqreg == "1"
                    ? this.coloresAplicar2.push(0)
                    : this.coloresAplicar2.push(1);
                  this.textoLe.push("");
                }

                this.coloresAplicar.push(0);
                this.texto.push("ROJO");
                break;
              case "P":
                if (colorImportante == true) {
                  element.bandera_estado_lectura_examenes == "1"
                    ? this.coloresAplicar2.push(2)
                    : this.coloresAplicar2.push(0);
                  this.textoLe.push("LE");
                } else {
                  element.reqreg == "1"
                    ? this.coloresAplicar2.push(0)
                    : this.coloresAplicar2.push(1);
                  this.textoLe.push("");
                }

                this.coloresAplicar.push(2);
                this.texto.push("AZUL");
                break;
            }
          });
          console.log(this.mail);
          //Intentamos asignar los datos correspondientes a la vista, aquí indicaremos si los botones o el background cuentan con cierto color
          //O si se tiene cierto texto, todo en función del triaje
        } catch (error) {
          this.consecutivosMostrar = true;
          //En caso de que exista un error que se genera debido a que no se encuentra ningún resultado, entonces mostrara no se tiene consecutivos asignados
        }
        $("#boxLoading").removeClass("loading");
      });
    document.addEventListener("click", (event) => {
      if (event.path[0].id != "ellipsis-v") {
        this.Mostrar = false;
      }
    });
    document.body.style.backgroundColor = "white";
    //Cambiamos el color del background
  },
  methods: {
    salir: function () {
      this.$router.replace({
        name: "Home",
      });
      //Esta función sirve para redigir al usuario al login
    },
    redirigir: function (consecutivo, color, texto, texto2) {
      if (texto2 == "") {
        localStorage.setItem("consecutivo", consecutivo);
        localStorage.setItem("color", color);
        localStorage.setItem("texto", texto);
        this.$router.push({
          name: "Consecutivos",
        });
      } else {
        localStorage.setItem("consecutivo", consecutivo);
        localStorage.setItem("color", color);
        localStorage.setItem("texto", texto);
        this.$router.push({
          name: "Consecutivos2",
        });
      }

      //Esta función sirve para almacenar el consecutivo, color y texto de lo que estamos usando para su uso posterior. Para luego enviarnos
      //a la segunda vista en el flujo que son los consecutivos
    },
    recargar: function () {
      $("#boxLoading").addClass("loading");
      this.consecutivos = [];
      this.reqord = [];
      this.coloresAplicar2 = [];
      this.coloresAplicar = [];
      this.texto = [];
      axios
        .post(url, {
          movil: localStorage.getItem("movil"),
          opcion: 2,
        })
        .then((response) => {
          try {
            response.data.data.forEach((element) => {
              this.consecutivos.push(element.reqcsc);
              this.reqord.push(element.reqord);
              var colorImportante;

              console.log(element.bandera_estado_lectura_examenes);
              if (element.estado_lectura_examenes == 1) {
                colorImportante = true;
              }
              switch (element.reqcda) {
                case "V":
                  if (colorImportante == true) {
                    element.bandera_estado_lectura_examenes == "1"
                      ? this.coloresAplicar2.push(2)
                      : this.coloresAplicar2.push(0);
                    this.textoLe.push("LE");
                  } else {
                    element.reqreg == "1"
                      ? this.coloresAplicar2.push(0)
                      : this.coloresAplicar2.push(1);
                    this.textoLe.push("");
                  }

                  this.coloresAplicar.push(3);
                  this.texto.push("VERDE");
                  break;
                case "A":
                  if (colorImportante == true) {
                    element.bandera_estado_lectura_examenes == "1"
                      ? this.coloresAplicar2.push(2)
                      : this.coloresAplicar2.push(0);
                    this.textoLe.push("LE");
                  } else {
                    element.reqreg == "1"
                      ? this.coloresAplicar2.push(0)
                      : this.coloresAplicar2.push(1);
                    this.textoLe.push("");
                  }

                  this.coloresAplicar.push(1);
                  this.texto.push("AMARILLO");
                  break;
                case "R":
                  if (colorImportante == true) {
                    element.bandera_estado_lectura_examenes == "1"
                      ? this.coloresAplicar2.push(2)
                      : this.coloresAplicar2.push(0);
                    this.textoLe.push("LE");
                  } else {
                    element.reqreg == "1"
                      ? this.coloresAplicar2.push(0)
                      : this.coloresAplicar2.push(1);
                    this.textoLe.push("");
                  }

                  this.coloresAplicar.push(0);
                  this.texto.push("ROJO");
                  break;
                case "P":
                  if (colorImportante == true) {
                    element.bandera_estado_lectura_examenes == "1"
                      ? this.coloresAplicar2.push(2)
                      : this.coloresAplicar2.push(0);
                    this.textoLe.push("LE");
                  } else {
                    element.reqreg == "1"
                      ? this.coloresAplicar2.push(0)
                      : this.coloresAplicar2.push(1);
                    this.textoLe.push("");
                  }

                  this.coloresAplicar.push(2);
                  this.texto.push("AZUL");
                  break;
              }
            });
            //Intentamos asignar los datos correspondientes a la vista, aquí indicaremos si los botones o el background cuentan con cierto color
            //O si se tiene cierto texto, todo en función del triaje
          } catch (error) {
            this.consecutivosMostrar = true;
            //En caso de que exista un error que se genera debido a que no se encuentra ningún resultado, entonces mostrara no se tiene consecutivos asignados
          }
          $("#boxLoading").removeClass("loading");
        });
      //Esta función es muy similar a lo que hicimos en el mounted, a diferencia de que aquí borramos los datos previamente obtenidos para consultarlos de nuevo,
      //de esa manera actualizando el contenido obtenido
    },
    descargar() {
      this.Mostrar = false;
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 36,
          consecutivo: this.consecutivo,
        })
        .then((response) => {
          if (response.data == 2) {
            axios({
              url: url,

              method: "POST",
              data: {
                consecutivo: this.consecutivo,
                opcion: 35,
                documento: this.datos.documento,
              },

              responseType: "blob",
            }).then((response) => {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));

              var fileLink = document.createElement("a");

              fileLink.href = fileURL;

              fileLink.setAttribute("download", "historia.pdf");

              document.body.appendChild(fileLink);
              $("#boxLoading").removeClass("loading");
              fileLink.click();
            });
          } else {
            $("#boxLoading").removeClass("loading");
            this.$swal.fire(
              "Aun no completa su historia medica, pdf no habilitado",
              "",
              "error"
            );
          }
        });
    },
  },
};
</script>

<style>
h1 {
  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  top: -10px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0%;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

h1 {
  color: white;
}

.listas {
  padding: 10px;
  border-bottom: black solid 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.listas:hover {
  -webkit-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
  box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
}
.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif") 50% 50%
    no-repeat rgb(249, 249, 249);
  opacity: 0.8;
}
.el-select .el-select__tags-text {
  font-size: 120%;
}
.p-inputtext {
  font-size: 1.3rem !important;
}
.el-input__inner {
  font-size: 1.3rem !important;
}
.el-select-dropdown__item {
  font-size: 1.2rem !important;
}
.form-group {
  display: flex;
    justify-content: center;
}
</style>
